.skeleton {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background-color: var(--gray-5);
  border-radius: var(--border-radius);
  height: 100%;
  margin-bottom: 8px;
  line-height: inherit;
  width: 100%;
  max-width: 100%;
}

.skeleton.more-contrast {
  background-color: var(--gray-4);
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
