.input {
  --height: 44px;

  background-color: var(--white-100);
  border-radius: var(--border-radius);
  border: 1px solid var(--border-primary);
  box-shadow: none;
  caret-color: var(--blue-6);
  font-family: var(--font-secondary);
  font-size: 0.875rem;
  height: var(--height);
  line-height: 16px;
  outline: none;
  padding: 12px 10px;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100%;
}

.input:disabled {
  background-color: var(--gray-5);
  color: var(--gray-2);
}

textarea.input {
  height: auto;
}

.input__wrapper {
  display: block;
  width: 100%;
}

.input__label {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

/*
INTERACTIONS
*/
.input:active {
  outline: none;
}

.input:active,
.input:focus {
  border: 1px solid var(--blue-6);
  box-shadow: none;
}

/*
ERROR
*/
.input.error {
  color: var(--red-60);
  border-color: var(--red-60);
  caret-color: var(--red-60);
  background-color: transparent;
}

/*
ICON
*/
.inputControl {
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.inputIcon {
  position: absolute;
  width: 2.75rem;
  height: 2.75rem;
  left: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.input.hasIcon {
  padding-left: 2.75rem;
}

/*
RADIO
*/
.radioWrapper {
  display: flex;
}

.radioWrapper:hover,
.radioWrapper > *:hover {
  cursor: pointer;
}

.radioWrapper .label {
  font-family: var(--font-secondary);
  font-size: 0.875rem;
}

.radioWrapper svg {
  color: var(--blue-4);
}

.radioWrapper .input__wrapper {
  width: 0.5rem;
  margin-left: -1.5rem;
  width: 2rem;
  height: 1.5rem;
}

.radioWrapper .input__wrapper:hover,
.radioWrapper svg:hover {
  cursor: pointer;
}

.input[type='radio'] {
  appearance: none;
  background-color: transparent;
  border: 0;
  height: 1rem;
}

/*
CHECKBOX
*/
.checkboxWrapper {
  display: flex;
}

.checkboxWrapper .input__wrapper {
  height: 1.5rem;
  margin-left: -1rem;
  width: 1.5rem;
}

.checkboxWrapper input {
  height: 1rem;
}

.checkboxWrapper svg {
  align-self: center;
}

.checkboxWrapper label {
  color: var(--blue-2);
  font-family: var(--font-secondary);
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.checkboxWrapper .input[type='checkbox'] {
  appearance: none;
  background-color: transparent;
  border: 0;
  height: 1rem;
}

/*
DATE TYPE

Make the native datepicker stretch it over the whole field so you can
click anywhere in the input field to trigger the native datepicker
*/
input[type='date'] {
  position: relative;
  padding-left: 2.75rem;
}

input[type='date']::-webkit-calendar-picker-indicator {
  background-position: left;
  background-size: auto;
  bottom: 0;
  cursor: pointer;
  left: 0;
  padding-left: 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
}
