@import './_variables.css';
@import './_typography.css';
@import './_inputs.css';

/*
COMPONENTS
*/
@import './_panel.css';
@import './_skeleton.css';
@import './_toast.css';

@font-face {
  font-family: 'PP Telegraf-Regular';
  src: local('PP Telegraf-Regular'),
    url(../fonts/PPTelegraf-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'PP Telegraf-Medium';
  src: local('PP Telegraf-Medium'),
    url(../fonts/PPTelegraf-Medium.woff2) format('woff2');
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-primary);
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  background-color: var(--white-100);
  box-sizing: border-box;
  display: inherit;
  -webkit-font-smoothing: antialiased;
  height: 100%;
}

#__next {
  height: 100%;
  overflow: auto;
}

body.modal-open {
  overflow: hidden;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/
a {
  color: inherit;
  text-decoration: inherit;
}

ul {
  margin: 0;
  list-style: none;
  padding: 0;
  justify-self: inherit;
}

* {
  box-sizing: border-box;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; /* Remove default arrow */
  /* comment this out when running sb */
  background-image: url('/caret-down.svg');
  background-repeat: no-repeat;
  background-position: 99% center;
}

hr {
  color: var(--gray-4);
}

.icon {
  fill: var(--blue-1);
}

/*
UTILITIES
*/
.flex {
  display: flex;
}

.block {
  display: block;
}

.error-text {
  color: var(--red-60) !important;
}

.secondary-text {
  color: var(--blue-4) !important;
}

.worker__action {
  opacity: 1 !important;
}

/*
MEDIA QUERIES
*/
@media (min-width: 1024px) {
  body {
    overflow: hidden;
  }
  .hide-lg {
    display: none !important;
  }
}

@media (min-width: 640px) {
  .hide-sm {
    display: none;
  }
}

@media not all and (min-width: 768px) {
  .hide-max-md {
    display: none;
  }
}

@media not all and (min-width: 1024px) {
  .hide-max-lg {
    display: none;
  }
}
