:root {
  /* OLD COLORS */
  --black: #000000;
  --gray-1: #404040;
  --gray-2: #737373;
  --gray-3: #a6a6a6;
  --gray-4: #d9d9d9;
  --gray-5: #f6f6f6;
  --blue-1: #0a1727;
  --blue-2: #1c2837;
  --blue-3: #2f3947;
  --blue-4: #424a57;
  --blue-5: #2c4b76;
  --blue-6: #3a639c;
  --blue-7: #7297ca;
  --blue-8: #e1e9f4;
  --orange-1: #dd671e;
  --new-orange-orange-05: #feeeeb;
  --yellow-1: #ecac33;
  --yellow-7: #f9e4be;
  --green-1: #299c7d;
  --red-1: #d72c2c;

  /* PRIMITIVE COLORS */
  /* BLACK */
  --black-01: rgba(252, 252, 252, 1);
  --black-03: rgba(247, 247, 247, 1);
  --black-05: rgba(242, 242, 242, 1);
  --black-10: rgba(230, 230, 230, 1);
  --black-15: rgba(217, 217, 217, 1);
  --black-20: rgba(204, 204, 204, 1);
  --black-30: rgba(179, 179, 179, 1);
  --black-40: rgba(152, 152, 152, 1);
  --black-50: rgba(128, 128, 128, 1);
  --black-60: rgba(91, 91, 91, 1);
  --black-70: rgba(68, 68, 68, 1);
  --black-80: rgba(50, 50, 50, 1);
  --black-85: rgba(38, 38, 38, 1);
  --black-90: rgba(26, 26, 26, 1);
  --black-95: rgba(13, 13, 13, 1);
  --black-98: rgba(5, 5, 5, 1);
  --black-100: rgba(0, 0, 0, 1);
  --black-100-a05: rgba(0, 0, 0, 0.05);
  --black-100-a10: rgba(0, 0, 0, 0.1);
  --black-100-a15: rgba(0, 0, 0, 0.15);
  --black-100-a20: rgba(0, 0, 0, 0.2);
  --black-100-a30: rgba(0, 0, 0, 0.3);
  --black-100-a40: rgba(0, 0, 0, 0.4);
  --black-100-a50: rgba(0, 0, 0, 0.5);
  --black-100-a60: rgba(0, 0, 0, 0.6);
  --black-100-a80: rgba(0, 0, 0, 0.8);

  /* WHITE */
  --white-100: rgba(255, 255, 255);
  --white-100-a05: rgba(255, 255, 255, 0.05);
  --white-100-a10: rgba(255, 255, 255, 0.1);
  --white-100-a15: rgba(255, 255, 255, 0.15);
  --white-100-a20: rgba(255, 255, 255, 0.2);
  --white-100-a30: rgba(255, 255, 255, 0.3);
  --white-100-a40: rgba(255, 255, 255, 0.4);
  --white-100-a50: rgba(255, 255, 255, 0.5);
  --white-100-a60: rgba(255, 255, 255, 0.6);
  --white-100-a70: rgba(255, 255, 255, 0.7);
  --white-100-a80: rgba(255, 255, 255, 0.8);
  --white-100-a90: rgba(255, 255, 255, 0.9);
  /* BLUE */
  --blue-05: rgba(240, 241, 254, 1);
  --blue-10: rgba(227, 229, 253, 1);
  --blue-20: rgba(193, 199, 250, 1);
  --blue-30: rgba(166, 174, 248, 1);
  --blue-40: rgba(131, 144, 245, 1);
  --blue-50: rgba(102, 121, 242, 1);
  --blue-60: rgba(46, 87, 237, 1);
  --blue-65: rgba(37, 75, 211, 1);
  --blue-70: rgba(31, 64, 186, 1);
  --blue-70-a05: rgba(31, 64, 186, 0.05);
  --blue-70-a10: rgba(31, 64, 186, 0.1);
  --blue-70-a15: rgba(31, 64, 186, 0.15);
  --blue-75: rgba(26, 55, 164, 1);
  --blue-80: rgba(21, 47, 142, 1);
  --blue-90: rgba(8, 24, 83, 1);

  /* CONCRETE */
  --concrete-05: rgba(246, 241, 234, 1);
  --concrete-10: rgba(236, 229, 216, 1);
  --concrete-10-a05: rgba(236, 229, 216, 0.05);
  --concrete-10-a10: rgba(236, 229, 216, 0.1);
  --concrete-10-a15: rgba(236, 229, 216, 0.15);
  --concrete-10-a20: rgba(236, 229, 216, 0.2);
  --concrete-10-a40: rgba(236, 229, 216, 0.4);
  --concrete-10-a70: rgba(236, 229, 216, 0.7);
  --concrete-15: rgba(227, 215, 193, 1);
  --concrete-20: rgba(212, 202, 182, 1);
  --concrete-30: rgba(190, 179, 158, 1);
  --concrete-40: rgba(160, 151, 133, 1);
  --concrete-50: rgba(135, 127, 112, 1);
  --concrete-60: rgba(109, 102, 89, 1);
  --concrete-70: rgba(72, 68, 59, 1);
  --concrete-80: rgba(54, 50, 43, 1);
  --concrete-90: rgba(30, 27, 23, 1);

  /* GREEN */
  --green-05: rgba(219, 254, 240, 1);
  --green-10: rgba(184, 254, 228, 1);
  --green-15: rgba(144, 254, 216, 1);
  --green-20: rgba(105, 253, 209, 1);
  --green-30: rgba(67, 234, 189, 1);
  --green-40: rgba(56, 199, 161, 1);
  --green-50: rgba(45, 169, 136, 1);
  --green-60: rgba(30, 123, 95, 1);
  --green-70: rgba(20, 92, 72, 1);
  --green-80: rgba(13, 69, 53, 1);
  --green-90: rgba(4, 36, 27, 1);

  /* ORANGE */
  --orange-05: rgba(255, 238, 235, 1);
  --orange-10: rgba(255, 220, 215, 1);
  --orange-20: rgba(254, 185, 172, 1);
  --orange-30: rgba(254, 150, 126, 1);
  --orange-40: rgba(254, 117, 72, 1);
  --orange-50: rgba(240, 93, 11, 1);
  --orange-60: rgba(175, 66, 6, 1);
  --orange-70: rgba(134, 48, 3, 1);
  --orange-80: rgba(99, 34, 2, 1);
  --orange-90: rgba(54, 15, 0, 1);

  /* RED */
  --red-05: rgba(253, 238, 238, 1);
  --red-10: rgba(252, 220, 221, 1);
  --red-15: rgba(251, 202, 204, 1);
  --red-20: rgba(250, 184, 185, 1);
  --red-30: rgba(248, 151, 153, 1);
  --red-40: rgba(247, 112, 115, 1);
  --red-50: rgba(246, 76, 82, 1);
  --red-60: rgba(220, 35, 46, 1);
  --red-60-a05: rgba(220, 35, 46, 0.05);
  --red-60-a10: rgba(220, 35, 46, 0.1);
  --red-60-a15: rgba(220, 35, 46, 0.15);
  --red-65: rgba(194, 29, 39, 1);
  --red-70: rgba(168, 24, 33, 1);
  --red-80: rgba(128, 15, 22, 1);
  --red-90: rgba(75, 5, 9, 1);

  /* TEAL */
  --teal-05: rgba(214, 255, 255, 1);
  --teal-10: rgba(169, 255, 255, 1);
  --teal-15: rgba(132, 255, 255, 1);
  --teal-20: rgba(81, 255, 255, 1);
  --teal-30: rgba(0, 254, 254, 1);
  --teal-40: rgba(0, 217, 217, 1);
  --teal-50: rgba(0, 183, 183, 1);
  --teal-60: rgba(0, 131, 131, 1);
  --teal-70: rgba(0, 97, 97, 1);
  --teal-75: rgba(0, 84, 84, 1);
  --teal-80: rgba(0, 71, 71, 1);
  --teal-80-a05: rgba(0, 71, 71, 0.05);
  --teal-80-a10: rgba(0, 71, 71, 0.1);
  --teal-80-a15: rgba(0, 71, 71, 0.15);
  --teal-85: rgba(0, 54, 54, 1);
  --teal-90: rgba(0, 38, 38, 1);

  /* YELLOW */
  --yellow-05: rgba(255, 241, 203, 1);
  --yellow-10: rgba(255, 231, 155, 1);
  --yellow-20: rgba(250, 213, 19, 1);
  --yellow-30: rgba(222, 189, 15, 1);
  --yellow-40: rgba(188, 160, 11, 1);
  --yellow-50: rgba(159, 135, 8, 1);
  --yellow-60: rgba(115, 97, 4, 1);
  --yellow-70: rgba(85, 71, 2, 1);
  --yellow-80: rgba(63, 52, 1, 1);
  --yellow-90: rgba(34, 27, 0, 1);

  /* ALIAS COLORS */
  /* TEXT */
  --text-primary: var(--black-100);
  --text-secondary: var(--black-100-a60);
  --text-tertiary: var(--black-100-a40);
  --text-primary-negative: var(--white-100);
  --text-secondary-negative: var(--white-100-a70);
  --text-tertiary-negative: var(--white-100-a40);
  --text-action: var(--blue-70);
  --text-on-action: var(--white-100);
  --text-on-action-alt: var(--blue-70);
  --text-success: var(--teal-80);
  --text-on-success: var(--white-100);
  --text-on-success-alt: var(--teal-80);
  --text-error: var(--red-60);
  --text-on-error: var(--white-100);
  --text-on-error-alt: var(--red-60);

  /* ICON */
  --icon-primary: var(--black-100);
  --icon-secondary: var(--black-100-a60);
  --icon-tertiary: var(--black-100-a40);
  --icon-primary-negative: var(--white-100);
  --icon-secondary-negative: var(--white-100-a60);
  --icon-tertiary-negative: var(--white-100-a40);
  --icon-action: var(--blue-70);
  --icon-on-action: var(--white-100);
  --icon-on-action-alt: var(--blue-70);
  --icon-success: var(--teal-80);
  --icon-on-success: var(--white-100);
  --icon-on-success-alt: var(--teal-80);
  --icon-error: var(--red-60);
  --icon-on-error: var(--white-100);
  --icon-on-error-alt: var(--red-60);

  /* BORDER */
  --border-focus: var(--blue-50);
  --border-primary: var(--black-100-a20);
  --border-primary-hovered: var(--black-100-a40);
  --border-primary-pressed: var(--black-100-a60);
  --border-secondary: var(--black-100-a10);
  --border-secondary-hovered: var(--black-100-a30);
  --border-secondary-pressed: var(--black-100-a50);
  --border-tertiary: var(--black-100-a60);
  --border-tertiary-hovered: var(--black-100-a80);
  --border-tertiary-pressed: var(--black-100);
  --border-primary-negative: var(--white-100-a20);
  --border-primary-negative-hovered: var(--white-100-a40);
  --border-primary-negative-pressed: var(--white-100-a60);
  --border-secondary-negative: var(--white-100-a10);
  --border-secondary-negative-hovered: var(--white-100-a30);
  --border-secondary-negative-pressed: var(--white-100-a50);
  --border-tertiary-negative: var(--white-100-a60);
  --border-tertiary-negative-hovered: var(--white-100-a80);
  --border-tertiary-negative-pressed: var(--white-100);
  --border-action: var(--blue-70);
  --border-on-action: var(--blue-50);
  --border-on-action-alt: var(--blue-70);
  --border-success: var(--teal-80);
  --border-on-success: var(--teal-60);
  --border-on-success-alt: var(--teal-80);
  --border-error: var(--red-60);
  --border-on-error: var(--red-40);
  --border-on-error-alt: var(--red-60);

  /* SURFACE */
  --surface-background: var(--white-100);
  --surface-primary: var(--white-100);
  --surface-secondary: var(--black-03);
  --surface-tertiary: var(--black-10);
  --surface-background-negative: var(--black-100);
  --surface-primary-negative: var(--black-100);
  --surface-secondary-negative: var(--black-95);
  --surface-tertiary-negative: var(--black-90);
  --surface-action: var(--blue-70);
  --surface-action-alt: var(--blue-10);
  --surface-success: var(--teal-80);
  --surface-success-alt: var(--green-10);
  --surface-error: var(--red-60);
  --surface-error-alt: var(--red-10);

  /* COMPONENT COLORS */
  /* INTERACTION :AFTERS */
  --ixn-hovered: var(--black-100-a05);
  --ixn-pressed: var(--black-100-a15);
  --ixn-negative-hovered: var(--white-100-a05);
  --ixn-negative-pressed: var(--white-100-a15);
  --ixn-action-hovered: var(--blue-70-a05);
  --ixn-action-pressed: var(--blue-70-a15);
  --ixn-success-alt-hovered: var(--teal-80-a05);
  --ixn-success-alt-pressed: var(--teal-80-a15);
  --ixn-error-alt-hovered: var(--red-60-a05);
  --ixn-error-alt-pressed: var(--red-60-a15);
  --ixn-button-primary-hovered: var(--black-100-a10);
  --ixn-button-primary-pressed: var(--black-100-a20);

  /* BADGE */
  --badge-notification-text: var(--white-100);
  --badge-notification-surface: var(--red-60);

  /* BUTTON */
  --button-primary-text: var(--white-100);
  --button-primary-text-label: var(--white-100);
  --button-primary-surface: var(--blue-70);
  --button-primary-error-surface: var(--red-60);
  --button-primary-error-text-label: var(--white-100);
  --button-secondary-border: var(--blue-70);
  --button-secondary-text-label: var(--blue-70);
  --button-secondary-error-border: var(--red-60);
  --button-secondary-error-surface: var(--red-60);
  --button-secondary-error-text-label: var(--red-60);
  --button-tertiary-text-label: var(--blue-70);
  --button-tertiary-error-text-label: var(--red-60);

  /* FONTS */
  --font-primary: 'PP Telegraf-Regular', sans-serif;
  --font-primary-bold: 'PP Telegraf-Medium', sans-serif;
  --font-secondary: 'PP Telegraf-Regular', sans-serif;

  --shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  --border-radius: 0.125rem;
  --border-radius-lg: 0.25rem;
  --border-radius-xlg: 0.375rem;

  --left-nav-width: 232px;
  --mobile-tabs-height: 48px;

  --card-corner: 0.5rem;

  /* SPACING */
  --spacing-l: 2rem;
  --spacing-xl: 4rem;
  --spacing-spacing-stack-lg: 1.5rem;
  --spacing-spacing-stack-md: 1rem;
  --list-inline-spacing: 1rem;
  --spacing-xs: 0.25rem;

  /* COMPONENTS */
  /* MODAL */
  --mobile-modal-top_bar-height: 4rem;
  --mobile-modal-top_bar-gap: 1rem;
  --mobile-modal-top_bar-padding-top: 1rem;
  --mobile-modal-top_bar-padding-horizontal: 1rem;
  --mobile-modal-top_bar-padding-bottom: 1rem;
  --mobile-modal-top_bar-padding-horizontal: 1rem;

  --modal-top_bar-height: 4.5rem;
  --modal-top_bar-padding-top: 2rem;
  --modal-top_bar-padding-horizontal: 2rem;
  --modal-top_bar-padding-bottom: 1rem;
  --modal-top_bar-padding-horizontal: 2rem;

  --mobile-modal-content-padding-top: 1rem;
  --mobile-modal-content-padding-horizontal: 1rem;
  --mobile-modal-content-padding-bottom: 2rem;
  --mobile-modal-content-padding-horizontal: 1rem;
  --mobile-modal-content-two_column-gap: 2rem;

  --modal-content-padding-top: 1rem;
  --modal-content-padding-horizontal: 2rem;
  --modal-content-padding-bottom: 2rem;
  --modal-content-padding-horizontal: 2rem;
  --modal-content-two_column-gap: 4rem;

  --mobile-modal-bottom_bar-gap: 1rem;
  --mobile-modal-bottom_bar-padding-vertical: 1rem;
  --mobile-modal-bottom_bar-padding-horizontal: 1rem;

  --modal-bottom_bar-gap: 1rem;
  --modal-bottom_bar-padding-vertical: 1rem;
  --modal-bottom_bar-padding-horizontal: 2rem;

  --modal-large-margin: 1rem;
  --modal-large-corner: 0.5rem;
  --modal-small-corner: 0.5rem;

  /* BUTTON */
  --button-corner: 0.25rem;
}
