@import './_variables.css';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
  font-weight: bold;
  margin: 0;
}

p,
span {
  font-family: var(--font-primary);
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
}

span {
  font-size: 0.75rem;
  line-height: 0.9rem;
}

h1 {
  font-size: 3rem;
  line-height: 4.5rem;
}
h2 {
  font-size: 2.5rem;
  line-height: 3.75rem;
}
h3 {
  font-size: 2rem;
  line-height: 3rem;
}
h4 {
  font-size: 1.5rem;
  line-height: 2.25rem;
}
h5 {
  font-size: 1rem;
  line-height: 1.125rem;
}
h6 {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.link {
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--blue-5);
  font-weight: bold;
}

.link:hover {
  cursor: pointer;
}

.body-x-small {
  color: var(--text-primary);
  font-feature-settings: 'ss04' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem; /* 133.333% */
}

.body-small {
  color: var(--text-primary);
  font-feature-settings: 'ss04' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem; /* 166.667% */
}

.body-medium {
  color: var(--text-primary);
  font-feature-settings: 'ss04' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}

.body-large {
  color: #000;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 155.556% */
}

.headline-small {
  color: var(--text-primary);
  font-feature-settings: 'ss04' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.75rem; /* 127.273% */
  letter-spacing: -0.015625rem;
}

.headline-medium {
  color: var(--text-primary);
  font-feature-settings: 'ss04' on, 'ss03' on, 'ss02' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.25rem; /* 120% */
  letter-spacing: -0.025rem;
}

.headline-large {
  color: var(--text-primary);
  font-feature-settings: 'ss02' on, 'ss03' on, 'ss04' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.75rem; /* 110% */
  letter-spacing: -0.0375rem;
}

.label-small {
  color: var(--text-primary);
  font-feature-settings: 'ss04' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.75rem;
}

.label-xsmall {
  color: var(--text-primary);
  font-feature-settings: 'ss04' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 0.625rem;
  font-style: normal;
  font-weight: 600;
  line-height: 0.625rem;
}

.label-medium {
  color: var(--text-primary);
  font-feature-settings: 'ss04' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 0.875rem;
}

.label-large {
  color: var(--text-primary);
  font-feature-settings: 'ss04' on, 'clig' off, 'liga' off;
  font-family: var(--font-primary);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}

.text-color-primary {
  color: var(--text-primary);
}

.text-color-secondary {
  color: var(--text-secondary);
}

.text-color-tertiary {
  color: var(--text-tertiary);
}

.text-color-secondary-negative {
  color: var(--text-secondary-negative) !important;
}

.text-color-blue {
  color: var(--blue-4);
}

.text-color-action {
  color: var(--text-action);
}

.text-color-error {
  color: var(--text-error);
}

.text-color-link {
  color: var(--blue-60);
}

.text-color-white {
  color: var(--white-100);
}

.text-color-success {
  color: var(--text-success);
}

.text-weight-regular {
  font-family: var(--font-primary-regular);
}

.text-weight-bold {
  font-family: var(--font-primary-bold);
}
