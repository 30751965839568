.panel {
  --padding-edge: 24px;
  --padding-inner: 12px;

  background-color: var(--white-100);
  border-radius: var(--border-radius-lg);
  box-shadow: var(--shadow);
}

.panel__block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: var(--padding-inner) var(--padding-edge);
  width: 100%;
}

.panel .panel__block:first-child {
  padding-top: 24px;
}

.panel__footer {
  border-top: 1px solid var(--gray-5);
  padding: var(--padding-inner) var(--padding-edge);
  justify-content: flex-end;
}
