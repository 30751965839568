.toast-container {
  --offset: 0.75rem;

  inset: 0 0 calc(var(--mobile-tabs-height) + var(--offset)) var(--offset) !important;
  z-index: 3 !important;
}

.toast-icon {
  color: var(--yellow-1);
  margin-left: 8px; /* Fix because of react-hot-toast annoying styling */
}

/*
LARGE SCREEN ADJUSTMENTS
*/
@media (min-width: 1024px) {
  .toast-container {
    inset: 0 0 var(--offset) calc(var(--left-nav-width) + var(--offset)) !important;
  }
}
